import * as React from "react"
import '../css/typography.css';
import favicon from '../images/favicon/favicon.ico';

import Favicon from 'react-favicon';

import projects from '../data/projects';

// styles
const pageStyles = {
  color: "#232129",
  backgroundColor: '#FDFFFC',
  // padding: getPagePadding(window.innerWidth),
  fontFamily: "manrope, -apple-system, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 12,
  maxWidth: 320,
  fontSize: 48,
  lineHeight: 1,
}
const paragraphStyles = {
  marginBottom: 48,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 400,
  fontSize: 14,
  maxWidth: 560,
  marginBottom: 30,
}
const projectListStyles = {
  display: 'flex',
  flexWrap: 'wrap',
}
const projectStyles = {
  listStyleType: 'none',
  maxWidth: 300,
  width: '100%',
  // border: '1px solid gray', 
  padding: 15,
  margin: 10,
  borderRadius: '3%',
  boxShadow: '0 0 10px lightgray',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'hidden',
}
const projectImgContainerStyles = {
  width: '125%',
  marginTop: '-7%',
}
const imgStyles = {
  width: '100%',
}
const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "Documentation",
  url: "https://www.gatsbyjs.com/docs/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

// data
/**
 * @type {Array<Object<{
 *  text: string,
 *  url: string,
 *  description: string,
 *  color: string
 * }>>}
 */
const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
  {
    text: "How to Guides",
    url: "https://www.gatsbyjs.com/docs/how-to/",
    description:
      "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#1099A8",
  },
  {
    text: "Reference Guides",
    url: "https://www.gatsbyjs.com/docs/reference/",
    description:
      "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#BC027F",
  },
  {
    text: "Conceptual Guides",
    url: "https://www.gatsbyjs.com/docs/conceptual/",
    description:
      "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#0D96F2",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    badge: true,
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
    color: "#663399",
  },
];

const skills = [
  'html',
  'css',
  'js',
  'node',
  'lit element',
  'web components',
  'react',
  'webpack',
  'gulp',
];

const currentlyLearning = [
  'three.js',
  'gatsby',
  'graphql'
];


// markup
const IndexPage = () => {
  const isBrowser = typeof window !== 'undefined';
  function getPagePadding(windowWidth) {
    if (windowWidth <= 350) {
      return 'none';
    } else if (windowWidth > 350 && windowWidth <= 545) {
      return 48;
    } else {
      return 96;
    }
  }
  return (
    <main style={{...pageStyles, padding: getPagePadding(isBrowser ? window.innerWidth : '')}} className="home">
      <Favicon url={favicon} />
      <title>Collin Cahill - Front-end engineer</title>
      <header>
        <h1 style={headingStyles}>
          collin cahill
        </h1>
        <p style={paragraphStyles}>
          front-end engineer
        </p>
      </header>
      <article>
        <h2>projects</h2>
        <ul style={{...listStyles, ...projectListStyles}}>
          {projects.map(project => (
            <li key={project.url} style={{...projectStyles}}>
              {project.imgUrl ?
              <div style={projectImgContainerStyles}>
                <img src={project.imgUrl} alt={project.description} style={imgStyles}></img>
              </div> : ''}
              <div>
                <h3>
                  <a
                    style={linkStyle}
                    href={`${project.url}`}
                    target="_blank"
                  >
                    {project.text}
                  </a>
                </h3>
                <p>{project.description}</p>
              </div>
              <div style={{width: '100%'}}>
                <h3>tools used:</h3>
                <p>{project.tools.join(', ')}</p>
              </div>
            </li>
          ))}
          {/* {links.map(link => (
            <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
              <span>
                <a
                  style={linkStyle}
                  href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
                >
                  {link.text}
                </a>
                {link.badge && (
                  <span style={badgeStyle} aria-label="New Badge">
                    NEW!
                  </span>
                )}
                <p style={descriptionStyle}>{link.description}</p>
              </span>
            </li>
          ))} */}
        </ul>
      </article>
      {/* <article>
        <h2>i'm currently learing</h2>
        <ul style={listStyles}>
          {currentlyLearning.map(thing => (
            <li style={{...listItemStyles}}>
              {thing}
            </li>
          ))}
        </ul>
      </article> */}
    </main>
  )
}

export default IndexPage
