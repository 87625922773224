import ProudAndTornImg from '../images/proudandtorn.png';
import MediaCultureImg from '../images/mediaculture.png';

/**
 * @type {Array<Object<{
 *  text: string,
 *  url: string,
 *  gitUrl: string,
 *  description: string,
 *  imgUrl: string,
 *  tools: Array<string>
 * }>>}
 */
const projects = [
  {
    text: 'Proud & Torn',
    url: 'https://proudandtorn.org',
    description: 'A visual memoir of Hungarian history, told with a modern perspective using exciting web technology.',
    imgUrl: ProudAndTornImg,
    tools: [
      'html', 'css', 'js', 'gulp', 'handlebars', 'gsap',
    ]
  },
  {
    text: 'Media & Culture Interactive Timeline',
    url: 'https://mediaculture.collincahill.dev',
    description: 'A timeline of significant events and inventions in mass communication, sorted by industry, year, or juxtaposed with other industries.',
    imgUrl: MediaCultureImg,
    tools: [
      'js', 'react', 'webpack', 
    ]
  },
  {
    text: 'Lit Element Todo App',
    url: 'https://git.collincahill.dev/collin/todo-lit',
    gitUrl: 'https://git.collincahill.dev/collin/todo-lit',
    description: 'Practice for Lit Element; this is a basic todo app based on TodoMVC.',
    tools: [
      'js', 'lit',
    ]
  },
];

export default projects;